<template>
  <div class="train">
      <div class="container" v-if="!showDetail">
          <div class="trainBox">
              <AppTitle utitle="职业技能培训" entitle="Vocational Skills Training" class="tit"/>
              <div class="box1">
                  <div class="fleBox" @click="gotopage('/category/sub/trainDetail?type='+info.id)">
                      <div>
                          <img :src="info.imgSrc"/>
                      </div>
                      <div class="text">
                          {{ info.title }}
                      </div>
                  </div>
                  <!-- <div class="fleBox"  @click="gotopage('/train/trainDetail?type=2')">
                      <div>
                          <img src="../../assets/images/train/ylhly.png"/>
                      </div>
                      <div class="text">
                          养老管理员
                      </div>
                  </div>
                  <div class="fleBox"  @click="gotopage('/train/trainDetail?type=3')">
                      <div>
                          <img src="../../assets/images/train/kffj.png"/>
                      </div>
                      <div class="text">
                          康复辅具技术咨询师
                      </div>
                  </div> -->
              </div>
          </div>
          <div class="trainBox">
              <AppTitle utitle="等级认定" entitle="Level Recognition" class="tit"/>
              <div style="text-align: center;">
                  <img src="../../assets/images/train/jzt.png" alt="" style="margin-left: 10px;">
              </div>
          </div>
          <div class="trainBox" style="height: 608px;">
              <AppTitle utitle="考生专区" entitle="Candidate Zone" class="tit"/>
              <div class="box2">
                 <div class="fleBox2">
                     <div class="scale" @click="gotoHref('https://zhenxinyihu.com/s/qFuaeYTQ')">
                          <img src="../../assets/images/train/bmb.png" alt="">
                     </div>
                     <div class="scale" @click="gotoHref('https://axypc.zyjnpx.com/')">
                          <img src="../../assets/images/train/ksjh.png" alt="">
                     </div>
                 </div>
                 <div class="fleBox2">
                     <div class="scale" @click="gotoHref('http://djrd.hbjdzx.org.cn/skillCognizanceLogin?random=3')">
                          <img src="../../assets/images/train/cjcx.png" alt="">
                     </div>
                     <div class="scale" @click="gotoHref('http://djrd.hbjdzx.org.cn/skillCognizanceLogin?random=3')">
                          <img src="../../assets/images/train/zscx.png" alt="">
                     </div>
                 </div>
              </div>
          </div>
          <div class="trainBox">
              <AppTitle utitle="报名缴费咨询" entitle="Registration And Payment Consultation" class="tit"/>
              <div class="box3">
                  <div class="fleBox3">
                      <div class="qcode">
                          <img src="" alt="">
                      </div>
                      <div class="text1">
                          扫码立即报名
                      </div>
                  </div>
                  <div class="fleBox3">
                      <div class="qcode">
                          <img src="" alt="">
                      </div>
                      <div class="text1">
                          关注微信公众号
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <router-view v-else></router-view>
  </div>
</template>
<script>
import AppTitle from '@/components/app-title.vue'
import { gotopage, gotoHref } from '@/hooks/index'
import { useDetail } from '@/hooks/useDetail';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
export default{
  name:'SubCategory',
  components:{AppTitle},
  setup(){
    const { showDetail } = useDetail()
    const route = useRoute()
    
    const info = computed(() => {
      if (route.params.id == 1) {
        return {
          title: "老年人能力评估师",
          imgSrc: require("@/assets/images/train/lnr.jpg"),
          id:1
        };
      } else if (route.params.id == 2) {
        return {
          title: "养老护理员",
          imgSrc: require("@/assets/images/train/ylhly.png"),
          id:2
        };
      } else {
        return {
          title: "康复辅助技术咨询师",
          imgSrc: require("@/assets/images/train/kffj.png"),
          id:3
        };
      }
    });
    return{info,showDetail,gotoHref,gotopage}
  }
}



</script>

<style lang="less" scoped>
.train {
  .container{
      padding-top: 52px;
      .trainBox{
          width: 1240px;
          height: 500px;
          background-color: #fff;
          margin-bottom: 38px;
          overflow: hidden;
          .tit{
              margin: 30px auto;
          }
          .box1{
              width: 1034px;
              height: 240px;
              margin:60px auto;
              display: flex;
              justify-content: center;
              .fleBox{
                  width: 400px;
                  height: 223px;
                  img{
                    width: 100%;
                  }
                  .text{
                      font-size: 24px;
                      font-weight: bold;
                      padding: 12px 10px;
                      text-align: center;
                  }
                  
              }
              .fleBox:hover{
                  transform: scale(1.1);
                  transition: all 0.6s;
                  cursor: pointer;
                  color: #D90D0D;
              }
          }
          .box2{
                  width: 850px; 
                  height: 434px;
                  margin: auto;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  .fleBox2{
                      width: 100%;
                      height: 198px;
                      display: flex;
                      justify-content: space-between;
                  }
                  .scale:hover{
                      transform: scale(1.1);
                      transition: all 0.6s;
                      cursor: pointer;
                  }
          }
          .box3{
              width: 638px;
              height: 262px;
              // background-color: skyblue;
              margin: 60px auto;
              display: flex;
              justify-content: space-between;
              .fleBox3{
                  width: 200px;
                  height: 100%;
                  // background-color: #f5f5f5;
                  .qcode{
                      width: 100%;
                      height: 200px;
                      background-color: grey;
                  }
                  .text1{
                      color: #D90D0D;
                      font-size: 21px;
                      font-weight: bold;
                      height: 62px;
                      line-height: 62px;
                      text-align: center;
                  }
              }
          }
      }
  }
}
</style>