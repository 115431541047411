<template>
    <div class="appTitle">
        <div class="top">
            <div class="red" style="margin-right: 20px;"></div>
            <div style="font-size: 36px;width: 220px;text-align: center;">{{ utitle }}</div>
            <div class="red" style="margin-left: 20px;"></div>
        </div>
        <div class="bottom">
            <span style="font-size: 20px;color:dimgrey ;">
                {{ entitle }}
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        name:"AppTitle",
        props:{
            utitle:{
                type:String,
                required:true
            },
            entitle:{
                type:String,
                required:true
            }

        }

    }
</script>

<style lang="less" scoped>
.appTitle{
    width: 430px;
    height: 66px;

    .top{
        width: 430px;
        height: 42px;
        // position: relative;
        display: flex;
        align-items: center;
        .red{
            display: inline-block;
            width: 86px;
            height: 4px;
            background-color: #D90D0D;
            // position: absolute;
            // top:50%;
        }
    }
    .bottom{
        text-align: center;
        margin-top: 3px;
    }
}
</style>